import React from "react"
import Layout from "../components/layout"
import PrintButton from "../components/printbutton"
import Seo from "../components/seo"

const RegistrationPage = () => {
  return (
    <Layout>
      <Seo title="CSICon: Register Today"></Seo>
      <h1 style={{ textAlign: "center", marginTop: "10px" }}>
        Unfortunately CSICon 2024 registration is no longer open. We hope to see
        you in June 2026 in beautiful Buffalo, NY.
        <hr />
      </h1>
    </Layout>
  )
}

export default RegistrationPage
